import image1 from '../images/confirmed/1.jpg';
import image2 from '../images/confirmed/2.jpg';
import image3 from '../images/confirmed/3.jpg';
import image4 from '../images/confirmed/4.jpg';
import image5 from '../images/confirmed/5.jpg';
import image6 from '../images/confirmed/6.jpg';



import i1 from '../images/common/1.png';


export const confirmed = [
    image1, image2, image3, image4, image5, image6
];

export const common = [
    i1
];